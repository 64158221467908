/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

body {
  background-color: #f7f7f7;
}

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #ed1c24;
	--ion-color-primary-rgb: 237,27,36;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #d11820;
	--ion-color-primary-tint: #ef323a;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/*********** Start Global Ionic Variable *********************/

.back-button-padding {
  padding-right: 16px;
}

/*********** End Global Ionic Variable ***********************/

.al-center {
    align-items: center;
}

.jc-center {
    justify-content: center;
}

ion-content {
  --background:#f7f7f7;
}

/**************************forgot-password**************** */

.the-title h1 {
  color: #6D6E72;
  font-family: 'TGProBold';
  font-size: 26px;
  margin-top: 0;
}

.notifi-item {
  position: relative;
  margin-bottom: 10px;
}

.notifi-avatar {
  background: #FFE9EA;
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  margin-right: 16px;
  position: relative;
}

.notifi-dot {
  background: var(--ion-color-primary);
  border-radius: 50%;
  height: 10px;
  width: 10px;
  bottom: 0;
  position: absolute;
  right: 5px;
}

.notifi-avatar ion-icon {
  color: var(--ion-color-primary);
  font-size: 30px;
}

.notifi-item ion-label h2 {
  color: #333436;
  font-family: 'TGProBold' !important; 
  font-size: 20px;
}

.notifi-item ion-label h3 {
  color: #333436;
  font-family: 'TGProBold' !important; 
  font-size: 20px;
}

.notifi-item ion-label h6 {
  color: #6D6E72;
  font-family: 'TG' !important; 
  font-size: 12px;
  margin-top: 10px;
}

.notifi-item .end-slot p {
  color: #000000;
  font-family: 'TG' !important; 
  font-size: 10px;
  margin: 0;
}

.back-btn {
  text-transform: none !important;
}